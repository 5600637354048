import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const handleClick = ({e,onClick}) => {
	if (onClick) {
		e.preventDefault();
		onClick();
	}

}

export default function SignupButton({children,to="/",onClick}) {
	return (
		<Link to={to} onClick={e => handleClick({e,onClick})}>
			<Button className="fs-4" style={{borderRadius:30,fontWeight: "bold"}}>
				{children}
			</Button>
		</Link>
	);
} 

import {Spinner} from 'react-bootstrap';

export default function FullScreenSpinner() {
       return (<div style={{
       position: "fixed",
       width: "100%",
       top:0,
       left:0,
       backgroundColor:"rgba(128,0,128,.3)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"

      }}>
      <Spinner animation="border"></Spinner>
      </div>)
}
import {useState,useEffect} from 'react';
import logo from '../Logo.png';
import '../App.scss';

import {Link, useNavigate} from 'react-router-dom';
import {Form, Button,Alert, Spinner} from 'react-bootstrap';

import { useMutation } from '@apollo/client';
import SetNewPassword from '../graphql/SetNewPassword';


const getQueryStringValue = (key) => {  
   return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
} 

export default function ResetPassword() {
 const navigate = useNavigate();

 const [password,setPassword] = useState();
 const [errorMessage,setErrorMessage] = useState();
 
	const [setNewPassword,{loading}] = useMutation(SetNewPassword, {
		onCompleted:  result => {
			navigate('/')
		},
		onError: error => {
		        const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'We had a problem updating your password. Give it another try.'
		        setErrorMessage(message);
		}
	})


	useEffect(() => {
		// when leaving page, reset variables
		return () => {
			setPassword(null);
			
		}
	},[])

	const handleSavePassword = (e) => {
		e.preventDefault();
		const token = getQueryStringValue("token");
		setNewPassword({variables:{newPassword:password,token}})

	}
  return (
    <div className="App">
        
          <Link to="/"><img src={logo} alt="Fleet Command Logo" className="m-5" width="200" height="95" style={{width:200,height:95}} /></Link>
          <h2 className="mb-5">Create a new password</h2>
          <Form className="mb-5" style={{width:500,maxWidth: "90%",margin:"auto"}} onSubmit={handleSavePassword}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Form.Group>
              <Form.Label>Enter your new password</Form.Label>
              <Form.Control type="password"  onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <div className="d-grid gap-2">
              <Button type="submit" className="mt-2" size="lg" >
              	Save new password {loading && <Spinner animation='border' />}
              </Button>
            </div>
          </Form>         
    </div>
  );
}


import {
	Card
} from 'react-bootstrap';


export default function SignupIntro({children}) {
	return (
		<Card className="ps-4 pe-4" style={{maxWidth:800,boxShadow:".05em .05em 1.15rem #ccc",borderRadius:"1.5em"}}>			
			<Card.Body className="text-center">
				{children}
			</Card.Body>
		</Card>
	)
}
import gql from 'graphql-tag';

export default gql`
mutation SelectServices(
	$shop: Boolean,
	$dispatch: Boolean
) {
  selectServices(
    	shop:$shop,
  		dispatch:$dispatch
   ) 
}
`;




import gql from 'graphql-tag';

export default gql`
	mutation Login($email: String!, $password: String!,$iotCode: String) {
	  login(email:$email,password:$password,iotCode:$iotCode) {
	  	id
	    token
	    lastServiceUsed
	    services
	  }
	}

`;
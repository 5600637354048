import React from 'react';



const featureCards = [
	{icon:"bi-wrench",iconType:"",title:"Work Orders",description:"See, track and schedule your fleet maintenance and repair requests all in one place."},
	{icon:"bi-thermometer-low",iconType:"",title:"Service Plans",description:"It's easy to schedule and implement preventive maintenance based on time or mileage."},
	{icon:"bi-tools",iconType:"",title:"Parts & Inventory Tracking",description:"Your team will be more productive and efficient, plus you'll always know what parts are in your shop."},
	{icon:"bi-alarm",iconType:"",title:"Vehicle Reminders",description:"Fleets can ensure compliance deadlines, such as federal annuals and vehicle registrations, are met and avoid fines."},
	{icon:"bi-file-person",iconType:"",title:"Driver Reminders",description:"Avoid driver downtime and citations by ensuring that all your drivers are in good legal standing."},
	{icon:"bi-file-text",iconType:"",title:"Vehicle History Reports",description:"Print a detailed report any time you need it."},
	{icon:"bi-input-cursor-text",iconType:"",title:"Custom Fields",description:"Keep track of vehicle and equipment features that are unique to your company."},
	{icon:"bi-phone",iconType:"",title:"Access Anywhere",description:"Our web-based and mobile apps let you access your fleet data from anywhere, on any device."},
	]

const KeyFeatures =  ({description}) =>  
    <div className="container mt-4 mb-4">
      <h2 className="text-center mb-4">Avoid the hassles of managing a repair shop</h2>
      {description && <div className="offset-2 col-8 p-0 pb-4">{description}</div>}
      <div className="row row-cols-1 row-cols-md-4 g-4 ms-0 me-0">
        {featureCards.map(({title,description,icon,link}) => <Card key={title} title={title} description={description} icon={icon} link={link} />)}
      </div>
    </div>


const Card = ({title,description,icon,link}) => {
	return (
		<div className="col text-center" >
			<div className="card h-100 mx-auto" >
			  <div className="card-body text-center">
			  	<i className={icon} style={{fontSize:40}}></i>
			    <h5 className="card-title">{title}</h5>
			    <p className="card-text">{description}</p>
			    
			  </div>
			</div>
		</div>

	);
}

export default KeyFeatures;
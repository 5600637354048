
import {Outlet} from 'react-router-dom';
import {
	Container
} from 'react-bootstrap';
import logo from '../Logo.png';
//import dispatchlogo from '../images/DispatchCommandLogo300.png';

export default function SignupLayout({children}) {

	


	return (
		<Container className="centered-container pb-5">
			<a href="https://www.fleetcommand.io"><img src={logo} alt="Fleet Command Logo" style={{height: 100,marginBottom: 30}} /></a>
			<Outlet />
			<footer className='mt-4 mb-4 w-100' style={{fontSize:14}}>
				<Container>
				<div className="float-start">
					&copy; {new Date().getFullYear()} Fleet Command, LLC |  <a href="https://fleetcommand.io/">Home</a>
				</div>
				<div className='float-end'>
					<a href="https://fleetcommand.io/privacy-policy">Privacy Policy</a>
				</div>
				</Container>
			</footer>
		</Container>
	)
}
import gql from 'graphql-tag';

export default gql`
mutation Signup(
	$firstName: String!,
	$lastName: String!,
	$company: String!,
	$email: String!,
	$password: String!,
	$acceptTerms: DateTime!,
	$phone: String,
	$referredBy: String,
	$achievement: String
) {
  signup(
    	firstName:$firstName,
  		lastName:$lastName,
    	company:$company,
    	email: $email,
    	password: $password,
    	acceptTerms: $acceptTerms,
    	phone:$phone,
    	referredBy: $referredBy,
    	achievement: $achievement
  ) {
	  	id
	    firstName
	    lastName
	    token
	    roles
  }
}
`;




import {useState} from 'react';
import logo from '../Logo.png';
import '../App.scss';

import {Link, useNavigate} from 'react-router-dom';
import {Form, Button,Alert, Spinner} from 'react-bootstrap';

import { useMutation } from '@apollo/client';
import ResetPassword from '../graphql/ResetPassword';


export default function ForgotPassword() {
  const navigate = useNavigate();

  const [email,setEmail] = useState();

  const [errorMessage,setErrorMessage] = useState();
  


  const [resetPassword,{loading}] = useMutation(ResetPassword, {
    onCompleted:  result => {
      navigate("/forgot-password-complete");
    },
    onError: error => {
            //console.log("error",error)
            // loading toast blocks header from being clicked
            const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'Uh oh. We had a problem resetting your password. Give it another shot.'
            setErrorMessage(message);
    }
  })


  return (
    <div className="App">
      
          <Link to="/"><img src={logo} alt="Fleet Command Logo" className="m-5" width="200" height="95" style={{width:200,height:95}} /></Link>
          <h2 className="mb-5">Forgot your password?</h2>
          <Form className="mb-5" style={{width:500,maxWidth: "90%",margin:"auto"}} onSubmit={(e) => {e.preventDefault();resetPassword({variables:{email}})}}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Form.Group>
              <Form.Label>Enter your email address</Form.Label>
              <Form.Control  onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <p className="mt-4">
              We'll send instructions immediately to get you back up and running.
            </p>
            <div className="d-grid gap-2">
              <Button type="submit" className="mt-2" size="lg" >
                Send password reset email {loading && <Spinner animation='border' />}
              </Button>
            </div>
          </Form>

      
    </div>
  );
}


import {useState, useEffect} from 'react';
import logo from '../Logo.png';
import '../App.scss';

//import FullScreenSpinner from '../components/FullScreenSpinner';

import {Link,useLocation} from 'react-router-dom';
import {Form, Button, Spinner, Alert} from 'react-bootstrap';

import { useMutation } from '@apollo/client';
import Login from '../graphql/Login';
//import GetLoginInfo from "../graphql/GetLoginInfo";


export default function HomePage() {
  //const navigate = useNavigate();
  const { search } = useLocation();
  const tvCode = new URLSearchParams(search).get('tv')


  const [loggedIn,setLoggedIn] = useState(false);
  const [email,setEmail] = useState();
  const [password,setPassword] = useState();
  const [errorMessage,setErrorMessage] = useState();



  const [login,{loading}] = useMutation(Login, {

    onCompleted:  result => {
      setLoggedIn(true);
    },
    onError: error => {
            console.log("error",error)
            // loading toast blocks header from being clicked
            const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error logging in. Please try again.'
            setErrorMessage(message);
    }
  })

  useEffect(() => {
    // when leaving page, reset variables
    return () => {
      setEmail(null);
      setPassword(null);
    }
  },[])

  const handleLogin = (e) => {
    e.preventDefault();
     if (!email || !password) {
       return;
     }

    login({variables:{email,password,iotCode:tvCode}})
  }



  return (
    <div className="App">
      
          <img src={logo} alt="Fleet Command Logo" className="m-5" width="200" height="95" style={{width:200,height:95}} />
          <h2 className="mb-5">Login with your tv or other connected device</h2>
          {loggedIn && <div>
            
            <h3>You are all set. Your device should automatically login momentarily.</h3>

          </div>}
          {!loggedIn &&
            <div>
              {!tvCode && 
                <div className="mb-4 mt-4">
                  Invalid Code. Please try re-scanning the QR code on your device.
                </div>
              }
              {tvCode &&

                  <Form className="mb-5" style={{width:400,maxWidth: "90%",margin:"auto"}} onSubmit={handleLogin}>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <Form.Group>
                      <Form.Label>Enter your email address</Form.Label>
                      <Form.Control onChange={e => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Enter your password</Form.Label>
                      <Form.Control type="password"  onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <Button type="submit" className="mt-2" size="lg">
                        Sign in {loading && <Spinner animation="border" />}
                       </Button>
                    </div>
                  </Form>
              }

              <p>
                <Link to="/forgot-password">Forgot your password?</Link><br />
                Let's reset it quickly so you can get back to work.
              </p>
              <p>
                
                Don't have an account yet? <Link to="/signup">Sign up here</Link>
              </p>

            </div>
          }
      
    </div>
  );
}


import logo from '../Logo.png';
import '../App.scss';

import {Link} from 'react-router-dom';


export default function ForgotPasswordComplete() {

  

  return (
    <div className="App">
        
          <Link to="/"><img src={logo} alt="Fleet Command Logo" className="m-5" width="200" height="95" style={{width:200,height:95}} /></Link>
          <h2 className="mb-5">Reset instructions sent</h2>
          <p>You are moments a way from being back up and running. Check your inbox for instructions for resetting your password.</p>
          
    </div>
  );
}


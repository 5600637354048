import {useState,useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import Button from './SignupButton';
import Card from './SignupCard';

import AppSwitch from '../images/AppSwitch.png';

import {Row,Col} from 'react-bootstrap';

export default function SetupComplete() {
	const location = useLocation();

	const [shop,setShop] = useState(false);
	const [dispatch,setDispatch] = useState(false);

	useEffect(() => {
		const {shop,dispatch} = location?.state?.services || {};

		if (shop)
			setShop(true);
		if (dispatch)
			setDispatch(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const renderNameText = () => {
		if (shop && dispatch) {
			return "Fleet Command Shop and Dispatch";
		}
		else if (dispatch) {
			return "Fleet Command Dispatch";
		}
		else {
			return "Fleet Command";
		}
	}

	return (
		<Card>
			<h2>You're all setup</h2>
			<p>You're account is ready to be used. Next, you will be taken to the dashboard of the app that you select. From there, you will be guided on some first steps to get the most out of {renderNameText()}. There will be an option in the top menu to switch between apps.</p>

			{shop &&
				<div className="mb-3">
					<Button  onClick={() => window.location = "/shop"}>Go to Fleet Command Shop</Button>
				</div>
			}
			{dispatch && 
				<div className="mb-3">
					<Button  onClick={() => window.location = "/dispatch/"}>Go to Fleet Command Dispatch</Button>
				</div>
			}

			{shop && dispatch && 
				<Row style={{border: "1px solid #000",borderRadius:10}}>
					<Col md={5} style={{paddingTop:"8%"}}>
						<p className="mt-auto">
							You can switch between products or get help from a human by clicking on your name in the upper right hand corner of the screen.
						</p>
						
					</Col>
					<Col md={4}>
						<img src={AppSwitch} alt="How to switch apps" width="400" height="240"/>
					</Col>
				</Row>
			}
			

		</Card>
	)
}
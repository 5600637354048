import { Fragment } from 'react';
import {Row,Col, Card} from 'react-bootstrap';

import DGCI from '../images/dgci_v3.png';
import KJTree from '../images/KJ_Logo.png';
import Daniels from '../images/DanielsAndSons.jpg';

export default function Testimonials() {
    return (
        <Fragment>
            <h2 className='mb-2 mt-4 text-center'>What our customers say</h2>
        <Row>
            <Col sm={12} md={4}>
                <div className="flex-1 px-12">
                <Card>
                    <Card.Body className='text-center'>
                        <img src={KJTree} alt="K&J Tree Service" />
                        <h3>K&J Tree Service</h3>
                        <p className='card-text fst-italic text-secondary '>We have found that the simplicity of Fleet Command is really what drives us to make full use of its features. Some software brands are too complex, and would take too long to implement. Fleet Command has allowed us to hit the ground running both from an inventory management side and a fleet management side.</p>  

                    </Card.Body>
                </Card>
                </div>
            </Col>
            <Col sm={12} md={4}>
            <div className="flex-1 px-12">
                <Card className='h-100'>
                <Card.Body className='text-center'>
                        <img src={DGCI} className='mt-4 mb-4' alt="Double Green Circle Inc"  />
                        <h3>DGCI</h3>
                        <p className='card-text fst-italic text-secondary '>My drivers and mechanics use Fleet Command every day to track truck maintenance. I love having instant access to my fleet's status and knowing all of our maintenance information is always available.</p>  

                    </Card.Body>
                </Card>
                </div>
            </Col>
            <Col sm={12} md={4}>
                <Card className='h-100'>
                <Card.Body className='text-center'>
                    
                    <img src={Daniels}  className='mt-4 mb-4' alt="Daniels and Sons" />
                        <h3>Daniels and Sons Construction</h3>
                        <p className='card-text fst-italic text-secondary '>Fleet Command makes it easy to stay on top of maintenance. Having instant access to equipment status has saved me a ton of time.</p>  

                    </Card.Body>
                </Card>
            </Col>
        </Row>

        </Fragment>
    );
}
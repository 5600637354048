import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

const API_URL = process.env.REACT_APP_API_URL + "/graphql";


const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
  headers: {
    authorization: localStorage.getItem('Authorization')
  }
});

export default function GraphQL({children}) {
  return (<ApolloProvider client={client}>{children}</ApolloProvider>)

} 
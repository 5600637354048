import {useState, useEffect} from 'react';
import logo from './Logo.png';
//import dispatchlogo from './images/DispatchCommandLogo300.png';
import './App.scss';

import FullScreenSpinner from './components/FullScreenSpinner';

import {Link, useNavigate} from 'react-router-dom';
import {Form, Button, Spinner, Alert} from 'react-bootstrap';

import { useMutation,useQuery } from '@apollo/client';
import Login from './graphql/Login';
import GetLoginInfo from "./graphql/GetLoginInfo";


export default function HomePage() {
  const navigate = useNavigate();

  const [email,setEmail] = useState();
  const [password,setPassword] = useState();
  const [errorMessage,setErrorMessage] = useState();


  const {loading:checkingLogin} = useQuery(GetLoginInfo,{
    fetchPolicy:'network-only',
    onCompleted: result => {
      // should only get here if logged in.
      const {lastServiceUsed,services} = result?.me || {};
      // if last service is empty, see if any services selected. if yes, go to first entry. else do nothing
      let redirectTo;

      if (lastServiceUsed === "Shop") {
        redirectTo = "/shop/";
      } else if (lastServiceUsed === "Dispatch") {
        redirectTo = "/dispatch/"
      } else if (services && services.length > 0 && services[0] === "Shop") {
        redirectTo = "/shop/"
      } else if (services && services.length > 0 && services[0] === "Dispatch") {
        redirectTo = "/dispatch/"
      }

      if (redirectTo) {
        window.location = redirectTo;
      }
    }
  });


  const [login,{loading}] = useMutation(Login, {

    onCompleted:  result => {
      localStorage.setItem("Authorization",result.login.token);
      // go to last product used
      const currentService = result?.login?.lastServiceUsed;
      const {services} = result?.login



      // if nothing, they haven't selected a service
      if (!services || services.length === 0) {
        navigate("/signup/product-selection",{state:"It looks like you haven't selected your products yet"})
      }
      else if (currentService && currentService === 'Dispatch') {
        window.location = "/dispatch/";
      }
      else if (currentService && currentService === 'Shop') {
        window.location = "/shop/";
      }
      else if (services && services.length > 0 && services[0] === "Shop") {
        window.location = "/shop/"
      } 
      else if (services && services.length > 0 && services[0] === "Dispatch") {
        window.location = "/dispatch/"
      }

    },
    onError: error => {
            console.log("error",error)
            // loading toast blocks header from being clicked
            const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error logging in. Please try again.'
            setErrorMessage(message);
    }
  })

  useEffect(() => {
    // when leaving page, reset variables
    return () => {
      setEmail(null);
      setPassword(null);
    }
  },[])

  const handleLogin = (e) => {
    e.preventDefault();
     if (!email || !password) {
       return;
     }

    login({variables:{email,password}})
  }

  // load spinner while checking access
  if (checkingLogin) {
     return <FullScreenSpinner />
  }

  return (
    <div className="App">

          <figure style={{display:"inline-block"}}>
            <img src={logo} alt="Fleet Command Logo" className="mx-4" width="200" height="95" style={{width:200,height:95}} />
            <figcaption >Maintenance | Dispatch | Inventory</figcaption>
          </figure>

          <h2 className="mb-5">Welcome back.<br />Let's sign in.</h2>
          <Form className="mb-5" style={{width:400,maxWidth: "90%",margin:"auto"}} onSubmit={handleLogin}>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Form.Group>
              <Form.Label>Enter your email address</Form.Label>
              <Form.Control onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Enter your password</Form.Label>
              <Form.Control type="password"  onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <div className="d-grid gap-2">
              <Button type="submit" className="mt-2" size="lg">
                Sign in {loading && <Spinner animation="border" />}
               </Button>
            </div>
          </Form>
          <p>
            <Link to="/forgot-password">Forgot your password?</Link><br />
            Let's reset it quickly so you can get back to work.
          </p>
          <p>
            
            Don't have an account yet? <Link to="/signup">Sign up here</Link>
          </p>
      
    </div>
  );
}

